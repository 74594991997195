namespace EditorTemplates.NullableEnumCheckboxes
{
    const editorType = "nullableEnumCheckboxes";

    export const setupEditors = () =>
    {
        const attributeNameEventsSetup = "data-events-setup";
        const $checkboxes = $(`input[data-editor-for='${editorType}']:not([${attributeNameEventsSetup}='true'])`);
        $checkboxes.on("click", uncheckOtherCheckboxes);
        $checkboxes.on("change", uncheckOtherCheckboxes);
        $checkboxes.attr(attributeNameEventsSetup, 'true');

        function uncheckOtherCheckboxes(event: JQueryEventObject): void
        {
            const $element: JQuery = $(event.currentTarget);

            const checked = $element.isChecked();

            if (checked)
            {
                const value = $element.val();

                const editorGroup = $element.data('editor-group');

                const $checkboxesToUncheck = $(`input[data-editor-for='${editorType}'][data-editor-group='${editorGroup}'][value!='${value}']:checked`);

                if ($checkboxesToUncheck.length > 0)
                {
                    $checkboxesToUncheck.prop('checked', false);
                    $checkboxesToUncheck.trigger("change");
                }
                else
                {
                    $element.trigger("nullableCheckboxChange");
                }
            }
            else 
            {
                if (event.type == "click") 
                {
                    $element.trigger("nullableCheckboxChange");
                }
            }
        }
    }
}

$(document).ready(EditorTemplates.NullableEnumCheckboxes.setupEditors);
$(document).ajaxComplete(EditorTemplates.NullableEnumCheckboxes.setupEditors);